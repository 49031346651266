@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Dillan';
  src: url('./Dosis-VariableFont_wght.ttf') format('opentype'); /* Adjust the path to your font file */
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.app {
  width: 100%;
  height: 100vh;
  color: white;
  overflow-y:scroll;
}

.light-theme {
  background: url('./bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: black; /* Adjust text color for light theme */
}

.dark-theme {
  background-color: black;
  color: white; /* Adjust text color for dark theme */
}
  .mobileHeader{
    
    
    background: url('./header.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    
}
/* Add to your index.css or a separate CSS file */
.section {
    position: relative;
    z-index: 1;
    transition: transform 0.5s ease-in-out;
  }
  
  .section.in-view {
    z-index: 2;
    transform: translateY(-100px);
  }
  
  .bg-white {
    background-color: white;
  }
  
  .bg-gray-100 {
    background-color: #f7fafc;
  }
  
 

/* For Firefox */

.GlassBgBlack{
  /* From https://css.glass */
background: rgba(0, 0, 0, 0.5);
border-radius: 0 0 16px 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

}
.GlassBgWhite{
  /* From https://css.glass */
background: rgb(137, 51, 249);
border-radius: 0 0 16px 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
color: white;
}
.main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 60rem;
  margin-bottom: 2rem;
}

.left-column,
.right-column {
  flex-basis: calc(50% - 1rem);
  margin-right: 1rem;
}

.right-column {
  margin-right: 0;
  margin-left: 1rem;
}

.borderStyle {
  border:0.01px solid rgb(54, 54, 53)
}

.footer{
  font-size: 15px;
}

.transitionStyle {
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.transitionStyleHover{
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
  
}


.media-item {
  margin-bottom: 30px;
  position: relative;
}

.media-item video {
  display: block;
}

.media-item video:hover {
  cursor: pointer;
}

.media-item video::-webkit-media-controls {
  display: none !important;
}

.media-item video::-webkit-media-controls-panel {
  display: none !important;
}

@media (hover: hover) {
  .media-item video:not(:hover) {
    pointer-events: none;
  }
}

.gallery-media {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: opacity 0.3s ease;
  position: relative; /* Ensure position context for absolute positioning */
}

.media-wrapper {
  position: relative;
}

.media-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.media-wrapper:hover .media-overlay {
  opacity: 1;
}

.play-pause-button,
.expand-button,
.mute-unmute-button {
  position: absolute;
  width: 40px;
  height: 40px;
  border: none;
  font-weight: 100;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; /* Initially invisible */
  transition: opacity 0.3s ease;
  border-radius: 50%; /* Circular button */
}
@media (max-width: 767px) {
  .play-pause-button,
  .expand-button,
  .mute-unmute-button {
    width: 30px;
  height: 30px; /* Make buttons visible on mobile screens */
  }
}
.media-wrapper:hover .play-pause-button,
.media-wrapper:hover .expand-button,
.media-wrapper:hover .mute-unmute-button {
  opacity: 1; /* Ensure visibility on hover */
}

.play-pause-button svg,
.expand-button svg,
.mute-unmute-button svg {
  width: 60%;
  height: 60%;
  fill: white; /* Set icon color to white */
  
}

.expand-button {
  top: 10px;
  right: 10px;
}

.play-pause-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mute-unmute-button {
  bottom: 10px;
  left: 10px;
}

/* Gallery.css */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.modal-close {
  position: absolute;
  top: 40px;
  right: 40px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 35px;
  color: #030303;
}

.modal-media {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}